@media (width <= 1000px) {
    html {
        font-size: 175%;
    }
    .main {
        margin: 8vw 0;
    }
}

@media (width >= 1000px) {
    html {
        font-size: 100%;
    }
    .main {
        margin: 2vw;
    }
}

body {
    background: #01161e;
    color: #eff6e0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: 100vh;
    margin: 0;
}

#root {
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}

#root h2, #root p {
    max-width: fit-content;
}

.title-top {
    font-family: 'Times', sans-serif;
    font-size: 3rem;
    margin: 2rem auto auto;
    line-height: 3.5rem;
}

.title-bottom {
    font-family: 'Caveat', sans-serif;
    font-size: 3rem;
    margin: -0.5rem auto;
}

hr {
    border-top: 0.1rem solid #eff6e0;
    margin: 2rem 0 1.25rem;
}

.typed-out{
    overflow: hidden;
    border-right: .08em solid orange;
    white-space: nowrap;
    animation: typing 4s steps(30, end) forwards, blinking .9s infinite;
    width: 0;
}

.select-carousel ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.select-carousel li {
    width: fit-content;
}

.select-carousel li {
    font-family: sans-serif;
    text-decoration: none;
    color: #AEC3B0;
    font-size: 1.35rem;
    cursor: pointer;
}

.select-carousel li:hover {
    color: #eff6e0;
}

.select-carousel li.active {
    color: #eff6e0;
    transition: ease-in-out 0.2s;
}

sub {
    color: #598392;
    padding: 0 5px;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
@keyframes blinking {
    from { border-color: transparent }
    to { border-color: orange; }
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.main p {
    align-self: center;
    
}

.container {
    position: relative;
    scroll-behavior: smooth;
    overflow: auto;
    flex-grow: 1;
    scroll-snap-type: y mandatory;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.container-footer {
    display: flex;
    flex-direction: row;
    gap: 1.75rem
}

.container-meta {
    flex-grow: 1;
}

.container-meta h4 {
    margin-bottom: 0;
    margin-top: 0;
    font-family: sans-serif;
    font-size: larger;
}

.container-meta span {
    font-family: 'Times';
    font-size: larger;
}

.container-download {
    display: flex;
    flex-direction: column;
}

.container-download span {
    text-align: center;
    font-family: sans-serif;
}

.section {
    width: 100%;
    height: 100%;
    background-size:  cover;
    scroll-snap-align: start;
}
.section:nth-child(1) {
    background: url(https://cdn.pixabay.com/photo/2014/09/05/18/32/old-books-436498_960_720.jpg);
}
.section:nth-child(2) {
    background: url(https://cdn.pixabay.com/photo/2015/06/02/12/59/narrative-794978_960_720.jpg);
}
.section:nth-child(3) {
    background: url(https://cdn.pixabay.com/photo/2016/09/08/22/43/books-1655783_960_720.jpg);
}
.section:nth-child(3) {
    background: url(https://cdn.pixabay.com/photo/2015/12/19/20/32/paper-1100254_960_720.jpg);
}
.section:nth-child(4) {
    background: url(https://cdn.pixabay.com/photo/2016/03/26/22/21/books-1281581_960_720.jpg);
}

.fa-animate-once {
    animation-name: fa-bounce;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }